import React from "react"
import { LinkedIn } from "@mui/icons-material"
import { Email } from "@mui/icons-material"
import { GitHub } from "@mui/icons-material"
import "../styles/Home.css"

export default function Home() {
  return <div className="home">
    <div className="about">
      <img src='/images/cropped.png' className="self" alt=""></img>
      <h2>Hi, my name is Jaclyn.</h2>
      <div className="prompt">
        <p> A coffee enthusiast interested in computation and finance.</p>
        <a href="https://www.linkedin.com/in/wang-jaclyn/" target="_blank" rel="noreferrer">
        <LinkedIn />
        </a>
        <a href="mailto:wang.jaclyn@outlook.com">
        <Email />
        </a>
        <a href="https://github.com/jjjaac" target="_blank" rel="noreferrer">
        <GitHub />
        </a>
      </div>
    </div>
    {/* <div className="skills">
        <h1> Skills</h1>
        <ol className="list">
          <li className="item">
            <h2> Organizational</h2>
            <span>Microsoft Word, Excel, Outlook, Powerpoint, S & P Capital IQ</span>
          </li>
          <li className="item">
            <h2> Programming</h2>
            <span>React JS, CSS, HTML, MySQL, NodeJS, Javascript, Java, Python, C, C++, Racket</span>
          </li>
        </ol>
      </div> */}
  </div>
}
