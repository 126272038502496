import React from 'react'
import "../styles/Footer.css"

export default function Footer() {
  return (
    <div className='footer'>
        <div className='socialMedia'> 
        </div>
        <p> &copy; 2024 Jaclyn Wang</p>
    </div>
  )
}

