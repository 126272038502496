import React from 'react'
import "../styles/About.css"

export default function About() {
    return <div className='about'>
      <div className='ctr'>
        <h2>About Me</h2>
        <div className='ctr2'>
          <img src='/images/selfiedog.png' className='dog' alt=''></img>
          <div className='ctr3'>
            <p>Hi! My name is Jaclyn. </p>
            <p>I am a second year Computer Science and Business Administration Double Degree student at the University of Waterloo and Wilfrid Laurier University. Pursuing a career in finance, I wish to integrate my knowledge of both programming and business to bring forth unique solutions to difficult problems.</p>
            <p>Outside of school, I am a massive fan of specialty coffee, matcha, long walks, and travelling. I love experiencing new things. After discovering online shopping, I have become a fan of fashion, in particular vintage collectables. </p>
            <p>Feel free to reach out anytime!</p>
          </div>
        </div>
      </div>
    </div>
  }
  