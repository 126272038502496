import React from 'react'
import ProjectItem from '../components/ProjectItem'
import "../styles/Projects.css"

export default function Projects() {
  return (
  <div className='projects'>
    <h1>My Personal Projects</h1>
    <div className='projectList'>
      <ProjectItem name="To be Added..." image="/images/wip.png"/>
    </div>
  </div>
  )
}
  