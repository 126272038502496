import { Link, useLocation } from "react-router-dom"
import "../styles/Navbar.css"
import ReorderIcon from '@mui/icons-material/Reorder';
import React, { useEffect, useState } from "react"

export default function Navbar() {
  const [expandNavbar, setExpandNavbar] = useState(false);
  const location = useLocation();
  useEffect(() => {
    setExpandNavbar(false);
  }, [location]);
  return (
    <nav className="navbar" id={expandNavbar ? "open" : "close"}>
      <div className="topbar">
        <Link to="/" className="site-title">Jaclyn Wang</Link>
        <div className="toggleButton">
          <button onClick={() => { setExpandNavbar((prev) => !prev) }}>
            <ReorderIcon />
          </button>
        </div>
      </div>
      <div className="links">
        <Link to="/about">About</Link>
        <Link to="/projects">Projects</Link>
        <a style={{display: "table-cell"}} href="https://drive.google.com/file/d/1MEvfza8067EdCIqmEYhpQQY8ipFe8OfT/view?usp=sharing" target="_blank" rel="noreferrer">Resume</a>
      </div>
    </nav>
  )
}

