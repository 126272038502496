import Navbar from "./components/Navbar"
import Footer from "./components/Footer"
import Projects from "./pages/Projects"
import Home from "./pages/Home"
import About from "./pages/About"
import { Route, Routes } from "react-router-dom"
import "./values/variables.css"
import "@fontsource/dm-sans"; 
import '@fontsource-variable/noto-sans-sc';


function App() {
  return (
    <div classname="App">
      <Navbar />
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/about" element={<About />} />
          <Route path="/resume"/>
        </Routes>
        <Footer />
      </div>
    </div>
  )
}

export default App
